import { initializeApp } from 'https://www.gstatic.com/firebasejs/10.3.1/firebase-app.js';
import { getAuth } from 'https://www.gstatic.com/firebasejs/10.3.1/firebase-auth.js';
 
export const config = {
    AthletesCollection: 'Athletes',
    DeadlinesColletion: 'Deadlines',
    MiniCompetitionsCollection: 'MiniCompetitions',
    RaceResultsCollection: 'RaceResults',
    StandingsCollection: 'Standings',
    TakenTeamNamesCollection: 'TakenTeamNames',
    UserCollection: 'Users2425',

    season: 'season2425',

    atleten_per_wedstrijd: 6,

  };

  
/*export const config = {
    AthletesCollection: 'Athletes',
    DeadlinesColletion: 'Deadlines',
    MiniCompetitionsCollection: 'MiniCompetitions',
    RaceResultsCollection: 'RaceResults',
    StandingsCollection: 'Standings',
    TakenTeamNamesCollection: 'Taken-team-names',
    UserCollection: 'Users',

    season: 'season2324',

    atleten_per_wedstrijd: 6,

  }; 
  */


const firebaseConfig = {
    apiKey: 'AIzaSyC0ytYCiRyW-t7emU8nkIxtZva7ae98ujc',
    authDomain: 'crosscupmanager-43eee.firebaseapp.com',
    projectId: 'crosscupmanager-43eee',
    storageBucket: 'crosscupmanager-43eee.appspot.com',
    messagingSenderId: '355297333887',
    appId: '1:355297333887:web:3ee9ff1dd3ef911d5f254a',
    measurementId: 'G-B545DSP8DZ',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };

